import styled from "styled-components"

export const Title = styled.h3`
  text-align: left;
`

export const SearchForm = styled.div`
  background-color: #eef8ff;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 40px;
  form {
    margin-bottom: 0;
  }
  .input-icon {
    > .form-control {
      padding-top: 10px;
      padding-bottom: 10px;
      border: 1px solid #dce7ef;
      &::placeholder {
        color: #004485;
      }
      &:focus,
      &:active {
        &::placeholder {
          color: #004485;
        }
      }
    }
    .icon {
      width: 45px;
      > svg {
        fill: ##062c44;
        width: 20px;
      }
    }
  }
`

export const SearchContainer = styled.div`
  position: relative;
`

export const CompareProducts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 320px;
  overflow-y: auto;
`

export const ComparisonItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  border-bottom: 1px solid lightgray;

  span {
    font-size: 14px;
  }

  div.action {
    padding: 4px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`

export const ModalActions = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`

export const HelpActionText = styled.div`
  display: block;
  font-size: 10px;
  line-height: 16px;
  color: #ff0000;
  padding: 0 15px;
`
