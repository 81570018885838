import React, { Component } from "react"
import styled from "styled-components"
import axios from "axios"
import LazyLaod from "react-lazyload"

import { Link, navigate, StaticQuery, graphql } from "gatsby"
import ArrowIcon from "../Icons/ArrowIcon"
import PhoneIcon from "../Icons/PhoneIcon"
import MapPointer from "../Icons/MapPointer"
import PaperPlan from "../Icons/PaperPlan"
import FaceBook from "../Icons/FaceBook"
import Youtub from "../Icons/Youtub"
import { CustomValidationMessage, RequiredTriangle } from "../FormStyle"
import PrimaryButton from "../Button/PrimaryButton"
import GoogleMap from "../GoogleMap"
import Pinterest from "../Pinterest"
import Instagram from "../Instagram"

const FooterWrapper = styled.div`
  color: white;
  padding: 30px 0px 60px;
  @media (min-width: 992px) {
    padding: 40px 0px 60px;
  }
  @media (min-width: 1200px) {
    padding: 60px 0px 60px;
  }
  @media (min-width: 1600px) {
    padding: 80px 0px 80px;
  }

  background: #1e4156;
  background: -moz-linear-gradient(
    -45deg,
    #1e4156 0%,
    #1e4156 50%,
    #062c44 50%,
    #062c44 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #1e4156 0%,
    #1e4156 50%,
    #062c44 50%,
    #062c44 100%
  );
  background: linear-gradient(
    106deg,
    #1e4156 0%,
    #1e4156 50%,
    #062c44 50%,
    #062c44 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e4156', endColorstr='#062c44',GradientType=1 );

  & h3 {
    color: white;
  }
  & p {
    color: white;    
    font-size: 14px;
    line-height: 22px;
    @media (min-width: 992px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
`
const AddressWrapp = styled.div`
  display: inline-block;
  vertical-align: top;
  z-index: 2;
  position: relative;
  width: 25%;
  & p {
    margin: 10px 0;
  }
  & a {
    color: #fff;
    &:hover,
    &:focus {
      color: #ed602b;
    }
  }
  & span {
    margin-right: 10px;
    vertical-align: text-top;
  }
  & input {
    width: 95%;
    margin-top: 49px;
    padding: 17px 26px;
    background: #1e4156;
    border: 1px solid white;
    border-radius: 30px;
    color: white;
    font-size: 16px;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 9999s ease-in-out 0s;
      -webkit-text-fill-color: #fff;
    }
  }
  & button {
    margin-left: -50px;
    background: rgba(255, 255, 255, 0.001);
    border: 0px;
    font-size: 0;
  }
  @media (max-width: 1230px) {
    & input {
      font-size: 15px;
    }
  }
  @media (max-width: 1024px) {
    width: 100%;
    display: block;
    & input {
      width: 100%;
      font-size: 16px;
      max-width: 350px;
      margin-top: 30px;
    }
  }
  @media (max-width: 768px) {
    max-width: 600px;
    margin: auto;
  }
`
const NavWrapp = styled.div`
  width: 73%;
  margin-left: 2%;
  display: inline-block;
  vertical-align: top;
  z-index: 2;
  position: relative;
  @media (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
    display: block;
    margin-top: 50px;
  }
  @media (max-width: 768px) {
    max-width: 600px;
    margin: auto;
    margin-top: 50px;
  }
`
const MenuGrid = styled.div`
  position: relative;
  z-index: 2;
  display: grid;
  grid-gap: 5px;
  margin-bottom: 30px;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, auto);
  }
`
const MenuItem = styled.p`
  margin-bottom: 10px;
  & a {
    color: white;
    &:hover,
    &:focus {
      color: #ed602b;
    }
  }
`
const CityWrap = styled.div`
`
const CityGrid = styled.ul`
  display: grid;
  z-index: 2;
  position: relative;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 26px;
  grid-template-columns: repeat(2, auto);
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, auto);
  }
`
const CityItem = styled.li`
  margin-bottom: 10px;
  @media only screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 20px;
  }
  & a {
    color: white;
    &:hover,
    &:focus {
      color: #ed602b;
    }
  }
`

const CopyRight = styled.div`
  z-index: 2;
  position: relative;
  width: 100%;
  text-align: center;
  display: block;
  font-size: 14px;
  color: #ccc;
`
const SocialIcons = styled.div`
  display: flex;
  position: relative;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    + a {
      margin-left: 15px;
    }
    &:hover {
      background: #ed602b;
    }
  }

  .instagram,
  .pinterest {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`

const CustomRequired = styled(CustomValidationMessage)`
  width: 250px;
  margin-top: 1px;
  z-index: 2;
`

const FooterCta = styled.div`
  background-color: #fff;
  box-shadow: 0px -5px 25px 0px rgb(0 75 155 / 25%);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  text-align: center;
  display: block;
  padding: 5px 15px;
  @media (min-width: 768px) {
    display: none;
  }
  a {
    display: block;
  }
  .btn {
    display: flex;
  }
`

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newsletter: "",
      newsletterRequire: false,
      validEmail: false,
    }
    this.handleNewsletter = this.handleNewsletter.bind(this)
    this.submitNewsletter = this.submitNewsletter.bind(this)
  }
  handleNewsletter(event) {
    const { value } = event.target
    this.setState({
      newsletter: value,
      newsletterRequire: false,
      validEmail: false,
    })
  }
  submitNewsletter(e) {
    e.preventDefault()
    const { location } = this.props
    if (this.state.newsletter === "") {
      this.setState({ newsletterRequire: true })
    } else if (this.state.newsletter.indexOf("@") === -1) {
      this.setState({ validEmail: true })
    } else {
      const data = {
        source: location.origin,
        form: location.pathname + "newsletter form",
        page: location.pathname,
        email: this.state.newsletter,
      }
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: {
          Authorization:
            "Bearer " +
            "13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
        },
      })
        .then(res => {
          navigate("/thank-you-newsletter/")
        })
        .catch(error => {
          alert(error)
        })
    }
  }
  render() {
    const { newsletterRequire, validEmail } = this.state
    return (
      <FooterWrapper>
        <div className="container">
          <AddressWrapp>
            <h3>ProBuilt Address</h3>
            <p>
              <span>
                <MapPointer fill="white" />
              </span>{" "}
              <a
                href="https://maps.app.goo.gl/ypmUL8Sg1Ta7ST3b7"
                target="_blank"
                rel="noreferrer"
                aria-label="maps"
              >
                3752 W US Hwy 90, Lake City, Fl 32055
              </a>
            </p>
            <p>
              <a href="tel:8777541818">
                <span>
                  <PhoneIcon fill="white" />
                </span>{" "}
                (877) 754-1818
              </a>
            </p>
            <p>
              <span>
                <PaperPlan fill="white" />
              </span>
              <a href="mailto:info@probuiltsteel.com">info@probuiltsteel.com</a>
            </p>
            <SocialIcons>
              <a
                href="https://www.youtube.com/channel/UCgX_B_Nfel_Z9Tbo1JLSCCA"
                target="_blank"
                rel="noreferrer"
                aria-label="youtube"
              >
                <Youtub fill="white" />
              </a>
              <a
                href="https://www.facebook.com/ProBuiltSteelBuildings/"
                target="_blank"
                rel="noreferrer"
                aria-label="facebook"
              >
                <FaceBook fill="white" />
              </a>
              <a
                href="https://www.instagram.com/probuiltsteelbuildings/"
                target="_blank"
                rel="noreferrer"
                aria-label="instagram"
                className="instagram"
              >
                <Instagram fill="white" />
              </a>
              <a
                href="https://www.pinterest.com/probuiltsteelbuildings/"
                target="_blank"
                rel="noreferrer"
                aria-label="pinterest"
                className="pinterest"
              >
                <Pinterest fill="white" />
              </a>
            </SocialIcons>
            <form onSubmit={this.submitNewsletter}>
              <input
                type="email"
                placeholder="Subscribe to our Newsletter"
                name="newletter_email"
                id="newletter_email"
                onChange={this.handleNewsletter}
                aria-label="newsletter email"
              />
              <CustomRequired display={newsletterRequire ? "block" : "none"}>
                <RequiredTriangle />
                <span>Please input your email address</span>
              </CustomRequired>
              <CustomRequired display={validEmail ? "block" : "none"}>
                <RequiredTriangle />
                <span>Please input valid email address</span>
              </CustomRequired>
              <button type="submit" aria-label="subscribe">
                <ArrowIcon fill="#FFFFFF" />
              </button>
            </form>
            <LazyLaod>
              <GoogleMap />
            </LazyLaod>
          </AddressWrapp>
          <NavWrapp>
            <MenuGrid>
              <div>
                <h3>Our Buildings</h3>
                <MenuItem>
                  <Link to="/carports/"> Carports</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/garages/"> Garages</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/barns/"> Barns</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/rv-covers/"> RV Covers</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/utility-buildings/"> Utility Buildings</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/storage-buildings/"> Storage Buildings</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/commercial-metal-buildings/">
                    {" "}
                    Commercial Buildings
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/metal-building-homes/"> Metal Building Homes</Link>
                </MenuItem>
              </div>
              <div>
                <h3>ProBuilt Info</h3>
                <MenuItem>
                  <Link to="/about/"> About ProBuilt</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/service-area/"> Service Area</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/reviews/"> Reviews</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/faqs/"> Frequently Ask Questions</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/blog/"> Articles</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/contact-us/"> Contact us</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/infographics/"> Infographic</Link>
                </MenuItem>
                {/* <MenuItem><Link to="/products/"> All Building Products</Link></MenuItem> */}
              </div>
              <div>
                <h3>Buying Guide</h3>
                <MenuItem>
                  <Link to="/buying-guide/steel-building-components/">
                    Steel Building Components
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/buying-guide/metal-building-roof-guide/">
                    Metal Building Roof Guide
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/buying-guide/site-preparation-guide/">
                    Site Preparation Guide
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/buying-guide/order-installation-process/">
                    Order/Installation Process
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/buying-guide/financing-process/">
                    Financing Process
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/buying-guide/rto-process/"> RTO Process</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/buying-guide/permit-engineering-guide/">
                    Permit & Engineering Guide
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/buying-guide/metal-building-certification/">
                    Metal Building Certifications
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/buying-guide/metal-building-warranty/">
                    Metal Building Warranty
                  </Link>
                </MenuItem>
              </div>
              <div>
                <h3>Other Links</h3>
                <MenuItem>
                  <Link to="/privacy-policy/"> Privacy Policy</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/sitemap/"> Sitemap</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/case-studies/">Case Studies</Link>
                </MenuItem>
                <h3>State</h3>
                <MenuItem>
                  <Link to="/steel-buildings-florida"> Steel Building FL</Link>
                </MenuItem>
              </div>
            </MenuGrid>

            <CityWrap>
              <h3>Cities</h3>
              <CityGrid>
                <StaticQuery
                  query={graphql`
                    query CityLinksQuery {
                      cities: allContentfulCityLanding {
                        nodes {
                          url
                          cityName
                        }
                      }
                    }
                  `}
                  render={data => {
                    return data.cities.nodes.map((city, i) => {
                      return (
                        <CityItem key={i}>
                          <Link to={city.url}>{city.cityName}</Link>
                        </CityItem>
                      )
                    })
                  }}
                />
              </CityGrid>
            </CityWrap>
          </NavWrapp>
        </div>
        <div className="container footer-bottom">
          <CopyRight>
            © {new Date().getFullYear()} ProBuilt Steel Buildings. All rights
            reserved.
          </CopyRight>
        </div>
        <FooterCta className="footer-cta">
          <a href="tel:8777541818">
            <PrimaryButton text="(877) 754-1818" icon={<PhoneIcon />} />
          </a>
        </FooterCta>
      </FooterWrapper>
    )
  }
}
export default Footer
