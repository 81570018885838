import React, { Fragment, useContext, useEffect, useRef, useState } from "react"
import {
  Modal,
  ModalDialog,
  ModalContent,
  ModalBody,
  ModalBackdrop,
  CloseButton,
} from "../Modal"
import { Context } from "../context"
import CloseIcon from "../Icons/CloseIcon"
import {
  CompareProducts,
  ComparisonItem,
  ModalActions,
  SearchContainer,
  SearchForm,
} from "./styles"
import SearchIcon from "../Icons/SearchIcon"
import { useSearch } from "../../utils/useSearch"
import { DropSearch, DropSearchItem } from "../Section"
import CloseThinIcon from "../Icons/CloseThinIcon"
import SecondaryButton from "../Button/SecondaryButton"
import { navigate } from "gatsby"

const ManageComparisonModal = ({ onToggle }) => {
  const [query, setQuery] = useState("")
  const { comparisonData, addProductToComparison, removeProduct } = useContext(
    Context
  )
  const results = useSearch(query, "product")
  const [showSearchDropDown, setShowSearchDropDown] = useState(false)
  const searchInputRef = useRef()
  const targetRef = useRef(null)

  const handleClickOutside = event => {
    if (targetRef.current && !targetRef.current.contains(event.target)) {
      if (event.target.id !== searchInputRef.current.id) {
        setShowSearchDropDown(false)
      }
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  return (
    <Fragment>
      <Modal className={"modal fade show"} style={{ display: "block" }}>
        <ModalDialog className="modal-dialog modal-dialog-centered">
          <ModalContent className="modal-content">
            <ModalBody className="modal-body">
              <CloseButton className="close" onClick={onToggle}>
                <CloseIcon />
              </CloseButton>
              <SearchContainer>
                <SearchForm className="search-form">
                  <div className="input-icon right">
                    <input
                      ref={searchInputRef}
                      className="form-control"
                      type="text"
                      placeholder="Search..."
                      id="search"
                      name="search"
                      autoComplete="off"
                      onFocus={() => setShowSearchDropDown(true)}
                      onChange={e => setQuery(e.target.value)}
                      value={query}
                    />
                    <span className="icon">
                      <SearchIcon />
                    </span>
                  </div>
                </SearchForm>
                {showSearchDropDown && (
                  <DropSearch className="drop-search" ref={targetRef}>
                    {results.map((item, index) => (
                      <DropSearchItem
                        className="drop-search-item"
                        key={index}
                        onClick={() => {
                          addProductToComparison(item)
                          setQuery("")
                        }}
                      >
                        {item.title}
                      </DropSearchItem>
                    ))}
                  </DropSearch>
                )}
              </SearchContainer>
              <CompareProducts>
                {comparisonData.map((compareProduct, i) => (
                  <ComparisonItem key={i}>
                    <span>{compareProduct.title}</span>
                    <div
                      className="action"
                      onClick={() => removeProduct(compareProduct.id)}
                      onKeyDown={() => {}}
                      role="button"
                      tabIndex={0}
                    >
                      <CloseThinIcon fill="black" />
                    </div>
                  </ComparisonItem>
                ))}
              </CompareProducts>
              <ModalActions>
                <button
                  type="button"
                  aria-label="Compare"
                  onClick={() => navigate("/compare")}
                >
                  <SecondaryButton text="Compare" />
                </button>
              </ModalActions>
            </ModalBody>
          </ModalContent>
        </ModalDialog>
      </Modal>
      <ModalBackdrop className="fade show" />
    </Fragment>
  )
}

export default ManageComparisonModal
